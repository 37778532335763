import {Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalProvider, GlobalUrlProvider, StorageService } from '../global.provider';
import { Subscription } from 'rxjs';
import { CartService } from '../services/cart.service';
import { ProductsService } from '../services/products.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-init-company',
  templateUrl: './init-company.page.html',
  styleUrls: ['./init-company.page.scss'],
})

export class InitCompanyPage implements OnInit {
  scanActive = false;
  scanSubscription: Subscription
  kantineAvailable;
  kantineCode;
  activeKantineCode = true;
  isCreditActive = "false";
  isGroupSubsidyActive = "false";
  needLogin = "true";
  name: String;
  code: String;
  errorMessage = ""
  unavailableMessage: String = ""
  logoUrl = ""
  constructor(private cart: CartService,private userService:UserService, private productService: ProductsService, private cartService: CartService, private router: Router, private gProvider: GlobalProvider,
    private gUProvider: GlobalUrlProvider, private route: ActivatedRoute,
    private storageSerivce: StorageService, 
  ) {

    this.route.queryParamMap
      .subscribe((params) => {
        if (params.get('companyId')) {


          // this.route.paramMap.subscribe(params => {
          //   console.log()


          this.gProvider.companyId = params.get('companyId');
          this.gProvider.siteId = params.get('siteId');
          this.gProvider.systemId = params.get('systemId');


          this.gUProvider.setItem('companyId', params.get('companyId'));
          this.gUProvider.setItem('siteId', params.get('siteId'));

          this.gUProvider.setItem('qVar', `companyId=${this.gProvider.companyId}&siteId=${this.gProvider.siteId}&systemId=${this.gProvider.systemId}`);
          this.gUProvider.setItem('companyUrl', `company/${this.gProvider.companyId}`);
          this.gUProvider.setItem('productUrl', `${this.gUProvider.getItem('companyUrl')}/products`)
          this.gUProvider.setItem('siteUrl', `${this.gUProvider.getItem('companyUrl')}/sites/${this.gProvider.siteId}`)
          this.gUProvider.setItem('userUrl', `${this.gUProvider.getItem('companyUrl')}/sites/${this.gProvider.siteId}/users`)
          this.gUProvider.setItem('systemUrl', `${this.gUProvider.getItem('companyUrl')}/sites/${this.gProvider.siteId}/systems/${this.gProvider.systemId}`)
          this.gUProvider.setItem('orders', `orders`)
          this.gUProvider.setItem('orderDetail', `orderDetail`)
          this.gUProvider.setItem('transactionUrl', `${this.gUProvider.getItem('companyUrl')}/transactions`)
          this.gUProvider.setItem('addressObject', JSON.stringify({ companyId: this.gProvider.companyId, siteId: this.gProvider.siteId, systemId: this.gProvider.systemId }))
          if (this.gProvider.companyId &&
            this.gProvider.siteId &&
            this.gProvider.systemId
          ) {

            this.productService.getKantineDetail().subscribe(d => {
              this.kantineAvailable = d['kantineAvailble'] ? d['kantineAvailble'] : 'false'

              this.unavailableMessage = d['unavailableMessage'] ? d['unavailableMessage'] : 'No display'
              this.kantineCode = d['kantineCode'] ? d['kantineCode'] : '';
              this.activeKantineCode = d['activeKantineCode'] ? d['activeKantineCode'] : false

              this.isGroupSubsidyActive = d['isGroupSubsidyActive'] === 'true' ? "true" : "false"


              this.isCreditActive = d['isCreditActive'] === 'true' ? "true" : "false"
              // if (this.isCreditActive == "true")
              //   this.gUProvider.setItem('currency', `Credit`)
              // else
              this.gUProvider.setItem('currency', `NOK`)
              
              this.needLogin = (d['needLogin'] === 'true') ? 'true' : 'false'
              this.name = d['name'] ? d['name'] : 'Not available';
              this.logoUrl = d['logoUrl'] ? d['logoUrl'] : './assets/img/friggkantine-logo.png';
              this.gUProvider.setItem('kantineAvailble', this.kantineAvailable);

              if (this.kantineAvailable == 'true') {
                this.storageSerivce.get("uuid").then(uuid => {
                  if (!uuid) {
                    this.storageSerivce.set("uuid", this.storageSerivce.getuuid())
                  } else {
                    // console.log("uuid", uuid)
                  }
                })
                if (this.activeKantineCode == false && this.needLogin == "false") {
                  this.userService.setUser({ uid: "guest" });
                  this.router.navigate(['/tabs'])
                    .then(() => {
                      window.location.reload();
                    });
                } else if (this.needLogin == "true") {
                  this.router.navigate(['/login'])
                    .then(() => {
                      window.location.reload();
                    });
                }
              }
            })




          }
        }


        //   if (this.gProvider.companyId &&
        //     this.gProvider.siteId &&
        //     this.gProvider.systemId
        //   ) {
        //     console.log("subscribe", gUProvider)
        //     this.router.navigateByUrl('/tabs')
        //   }
        // });
        // this.router.paramMap.subscribe(doc=>{
        //   console.log("param",doc)
        //   this.gProvider.isAndroid = this.platform.is('android')
        this.cart.clear()
      })

  }

  checkCode() {
    if (this.code == this.kantineCode) {
      this.router.navigate(['/tabs'])
        .then(() => {
          window.location.reload();
        });

    } else {
      this.errorMessage = "Wrong Code..."
    }
  }
  ionViewWillEnter() {

    // this.startScan();
  }
  ionViewWillLeave() {
    // this.stopScanning();
  }


  ngOnInit() {
  }

  stopScan() {
    this.scanActive = false
  }

  // async startScan() {
  //   console.log("scanning")
  //   // (window.document.querySelector('ion-app') as HTMLElement).classList.add('cameraView');
  //   this.qrScanner.prepare()
  //     .then((status: QRScannerStatus) => {
  //       if (status.authorized) {
  //         this.qrScanner.show();
  //         this.scanSubscription = this.qrScanner.scan().subscribe((text: string) => {
  //           console.log(text, this.gProvider)
  //           let url = new URL(text)
  //           let params = url.searchParams;

  //           console.log(params.get('companyId'))
  //           console.log(params.get('siteId'))
  //           console.log(params.get('systemId'))
  //           this.gProvider.companyId = params.get('companyId');
  //           this.gProvider.siteId = params.get('siteId');
  //           this.gProvider.systemId = params.get('systemId');

  //           if (this.gProvider.isApp && (this.gProvider.isIos || this.gProvider.isAndroid)) {

  //             this.gUProvider.setItem('companyUrl', `/company/${this.gProvider.companyId}`);
  //             this.gUProvider.setItem('productUrl', `${this.gUProvider.getItem('companyUrl')}/products`)
  //             this.gUProvider.setItem('siteUrl', `${this.gUProvider.getItem('companyUrl')}/sites/${this.gProvider.siteId}`)
  //             this.gUProvider.setItem('systemUrl', `${this.gUProvider.getItem('companyUrl')}/sites/${this.gProvider.siteId}/systems/${this.gProvider.systemId}`)
  //             // this.gUProvider.setItem('orders', `${this.gUProvider.getItem('companyUrl')}/orders`)
  //             this.gUProvider.setItem('orders', `/orders`)
  //             this.gUProvider.setItem('orderDetail', `/orderDetail`)
  //             this.gUProvider.setItem('transactionUrl', `${this.gUProvider.getItem('companyUrl')}/transactions`)



  //             if (this.gProvider.companyId &&
  //               this.gProvider.siteId &&
  //               this.gProvider.systemId
  //             ) {
  //               console.log("subscribe", this.gUProvider)
  //               this.changingKantine();
  //               this.router.navigate(['/tabs'])
  //                 .then(() => {
  //                   window.location.reload();
  //                 });

  //             }
  //           }
  //         });
  //       } else {
  //         console.error('Permission Denied', status);
  //       }
  //     })
  //     .catch((e: any) => {
  //       console.error('Error', e);
  //     });
  // }

  changingKantine() {
    this.cartService.clear();
    this.productService.isKantineChanged = true
  }

  stopScanning() {
    (this.scanSubscription) ? this.scanSubscription.unsubscribe() : null;
    this.scanSubscription = null;
    // (window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');
  }



}
