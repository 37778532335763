import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
@Injectable({ providedIn: 'root' })
export class GlobalProvider {
    public isIos: boolean;
    public isAndroid: boolean;
    public isWeb: boolean;
    public isApp: boolean;
    public cartKey = 'shopcart';
    public companyId;
    public siteId;
    public systemId;
}



@Injectable({ providedIn: 'root' })
export class GlobalUrlProvider {

    // Create and expose methods that users of this service can
    // call, for example:
    public setItem(key: string, value: any) {
        localStorage.setItem(key, value);
    }

    public getItem(key: string) {
        return localStorage.getItem(key);

    }
}



@Injectable({ providedIn: 'root' })
export class StorageService {
    constructor(private storage: Storage) {
        this.storage.create()
    }

    // Create and expose methods that users of this service can
    // call, for example:
    public set(key: string, value: any) {
        this.storage?.set(key, value);
    }
    public get(key: string,) {
        return this.storage?.get(key);
    }


    public getuuid() {
        let unix = new Date().getTime().toString()
        return 'U' + unix
    }
}


