import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { IonicModule } from '@ionic/angular';

import { InitCompanyPageRoutingModule } from './init-company-routing.module';

import { InitCompanyPage } from './init-company.page';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    IonicModule,
    InitCompanyPageRoutingModule,

  ],
  declarations: [InitCompanyPage]
})
export class InitCompanyPageModule {}
