import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { GlobalProvider } from './global.provider';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private gProvider: GlobalProvider, private platform: Platform) {
    this.gProvider.isAndroid = this.platform.is('android');
    this.gProvider.isIos = this.platform.is('ipad') || this.platform.is('iphone')
      || this.platform.is('ios');
    this.gProvider.isWeb = this.platform.is('mobile');
    this.gProvider.isApp = this.platform.is('cordova');

    console.log(gProvider)
    // this.authService.device = this.gProvider.isAndroid ? 'Android' : 'Mobile';
  }
}
