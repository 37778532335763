import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductsModel } from '../products/products.model';
import { CartModel } from '../cart/cart-model';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  key: string;
  getNumSubject = new Subject();

  constructor(private translate:TranslateService, public atrCtrl:AlertController) {
    this.key = "shopcart"
  }

  async addCart(model: CartModel) {

    // console.log("before", this.getNum())
    if (Number(this.getNum()) <= 3 ) {
      let list: Array<CartModel> = JSON.parse(sessionStorage.getItem(this.key));
      if (list === null || list.length === 0) {
        list = new Array<CartModel>();
        list = [new CartModel(model), ...list];
      } else {
        const index = list.findIndex(g => g.id === model.id);
        if (index > -1) {
          list[index].num += model.num;
        } else {
          list = [new CartModel(model), ...list];
        }
      }
      // console.log(JSON.stringify(list))
      sessionStorage.setItem(this.key, JSON.stringify(list));
      this.getNum()
    } else {
      // throw new Error("cannot add")
      let header = ""
      let pop1text = ""
      this.translate.get('pop3.header').subscribe(text=>{
        header = text;
      })
      this.translate.get('pop3.text').subscribe(text=>{
        pop1text = text
      })


      let alert = await this.atrCtrl.create({
        header: header,
        cssClass: 'my-custom-alert',
        subHeader: pop1text,
          buttons: ['OK'],
      });


      await alert.present();
      throw new Error("limit exceded")
    }


  }

  public getList() {
    return JSON.parse(sessionStorage.getItem(this.key));
  }

  public changeNum(id: string, num: number) {

    const list: Array<CartModel> = JSON.parse(sessionStorage.getItem(this.key));
    list.find(g => g.id === id).num = num;
    sessionStorage.setItem(this.key, JSON.stringify(list));
    this.getNum()
  }

  public getNumSub() {
    return this.getNumSubject
  }

  public getNum() {
    const num: Array<CartModel> = JSON.parse(sessionStorage.getItem(this.key));
    if (num) {
      let finalQty = num.reduce((tot, arr) => {
        // return the sum with previous value
        return tot + arr.num;
        // set initial value as 0
      }, 0);

      // console.log("Fuck", num, finalQty)

      this.getNumSubject.next(num ? finalQty : 0);//list ? list.length : 0;

      return finalQty;
    }else{
      return 0
    }
  }


  public saveCart(list: Array<ProductsModel>) {
    sessionStorage.setItem(this.key, JSON.stringify(list));
  }

  public clear() {
    sessionStorage.removeItem(this.key);
    this.getNumSubject.next(0);

  }
}
