// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripeKey: "pk_live_51KQpDJJtw37SeErReMXuifarn0o2eI1hZ2pgygLs4aw0l5lw2xpvLf7zMLMxTtitrIFSeQrtX10e1285HmjVGR0X00klcqgxNx",
  // stripeKey:"pk_test_51KQpDJJtw37SeErRxqNmspyCIMv8CEGi3KlAsqg89bGgljFBLQQC2TDpzAT0Tnq9o0tgWqUTpjq8CZgX28I2sysc00NIegOYzc",
  apiUrl: "https://api.henther.no/vipps/v1/api",
  // apiUrl : "http://192.168.86.223:8001/api",
  backEnd: "https://europe-west1-frigg-kantine.cloudfunctions.net/widgets", 

  vippsDemoUrl: "https://api.henther.no/vippsdemo/v1/api",

  stripeUrl: "https://api.henther.no/stripe/v1",

  webUrl: "https://app.friggkantine.no", 
  // webUrl : "https://electron-dev.firebaseapp.com",
  // webUrl: "https://app.henther.no", 



  firebase: {
    apiKey: "AIzaSyA7aKWOp-LQNkE6PZ8KNL1CNvSifkPYZEI",
    authDomain: "frigg-kantine.firebaseapp.com",
    projectId: "frigg-kantine",
    databaseURL: 'https://frigg-kantine.firebaseio.com',
    storageBucket: "frigg-kantine.appspot.com",
    messagingSenderId: "308881591789",
    appId: "1:308881591789:web:ca4f20ef745f8d05636be4",
    measurementId: "G-GZPTHWTBBZ"
  }


};


// henther application

// export const environment = {
//   production: false,
//   stripeKey: "pk_live_51KQpDJJtw37SeErReMXuifarn0o2eI1hZ2pgygLs4aw0l5lw2xpvLf7zMLMxTtitrIFSeQrtX10e1285HmjVGR0X00klcqgxNx",
//   // stripeKey:"pk_test_51KQpDJJtw37SeErRxqNmspyCIMv8CEGi3KlAsqg89bGgljFBLQQC2TDpzAT0Tnq9o0tgWqUTpjq8CZgX28I2sysc00NIegOYzc",
//   apiUrl: "https://api.henther.no/vipps/v1/api",
//   // apiUrl : "http://192.168.86.223:8001/api",
//   backEnd: "https://europe-west1-frigg-kantine.cloudfunctions.net/widgets", 

//   vippsDemoUrl: "https://api.henther.no/vippsdemo/v1/api",

//   stripeUrl: "https://api.henther.no/stripe/v1",

//   webUrl: "https://app-henther.web.app", 


//   firebase: {
//     apiKey: "AIzaSyDEfym2Gqbe2GRLxXxA2k2qNtSCtWrFWqs",
//     authDomain: "app-henther.firebaseapp.com",
//     projectId: "app-henther",
//     storageBucket: "app-henther.appspot.com",
//     messagingSenderId: "984499963581",
//     appId: "1:984499963581:web:a06c55b0d20aad890319d4",
//     measurementId: "G-EH6HH64JMK"
//   }
// };




  // firebase   : {
  //   "apiKey": "AIzaSyB-TmHGbtvyaX7LTwDC2YU3OekAiIcqd14",
  //   "authDomain": "electron-beta-test.firebaseapp.com",
  //   "databaseURL": "https://electron-beta-test.firebaseio.com",
  //   "projectId": "electron-beta-test",
  //   "storageBucket": "electron-beta-test.appspot.com",
  //   "messagingSenderId": "578139832097",
  //   "appId": "1:578139832097:web:dcbdcdb757313978"
  // }