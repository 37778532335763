import { Component } from '@angular/core';

@Component({
  selector: 'custom-ok',
  templateUrl: './custom-ok.component.html',
  styleUrls: ['./custom-ok.component.scss'],
})
export class CustomOkComponent {
  constructor() { }

}
