import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BaseService } from './base.service';
import { MyError } from './my-error';
import { ProductsModel } from '../products/products.model';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, flatMap, map, mergeMap } from 'rxjs/operators';
import { GlobalUrlProvider } from '../global.provider';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends BaseService<ProductsModel> {

  constructor(
    private gUrlProvider: GlobalUrlProvider,
    public db: AngularFirestore,
    public myErr: MyError) {
    super(db, 'productUrl', myErr);
  }

  _isKantineChanged: Boolean = false;
  _products: Array<ProductsModel> = [];
  _categories: Array<any> = [];
  

  getStockData(active) {

    return this.db.doc(`${this.gUrlProvider.getItem('systemUrl')}/stocks/product_data`)
      .snapshotChanges().pipe(map((doc) => {
        return doc.payload.data()['data'].filter(ref => ref.active != active).map(obj => {
          return this.getModel(obj.id).pipe(map(p => {
            // console.log('p', p)
            return { ...p, id: obj.id, ref: p.ref, imageUrl: p.imageUrl, channel: obj, mva: obj.vat }

          }));
        })
      }), flatMap(doc => doc))
  }

  getStockQuantity() {
    // return new Promise((res, rej) => {
    return this.db.collection(`${this.gUrlProvider.getItem('systemUrl')}/stocks/product_data/stock_data`).snapshotChanges()
      .pipe(map(data => {
        return data.map(doc => {
          return doc.payload.doc.data()
        })
      })
      )    // })
  }

  getCategories(siteId) {
    // return new Promise((res, rej) => {
    return this.db.collection(`${this.gUrlProvider.getItem('companyUrl')}/siteCategories`, ref => ref.where('siteId', '==', siteId)).snapshotChanges()
      .pipe(map(data => {
        return data.map(doc => {
          return { id: doc.payload.doc.data()['id']?doc.payload.doc.data()['id'] :2, ...doc.payload.doc.data() as object,   }
        })
      })
      )    // })
  }

  getAllCategories() {
    // return new Promise((res, rej) => {
    return this.db.collection(`${this.gUrlProvider.getItem('companyUrl')}/categories`).snapshotChanges()
      .pipe(map(data => {
        return data.map(doc => {
          return { id: doc.payload.doc.data()['id']?doc.payload.doc.data()['id'] :2, name: doc.payload.doc.data()['name'], dbid: doc.payload.doc.id }
        })
      })
      )    // })
  }

  _setCategories(arr){
    this._categories = arr
  }

  _getCategories(name){
    console.log(name,this._categories)
    return new Promise((res,rej)=>{
      let c  = this._categories.find((_p)=> {
        console.log(  _p.name == name.toString())
      return _p.name == name.toString()
      })
      console.log(c)
      res(c)
    })
  }

  getKantineDetail() {
    // return new Promise((res, rej) => {
    return this.db.doc(`${this.gUrlProvider.getItem('systemUrl')}`)
      .snapshotChanges().pipe(map((doc) => {
        // console.log("data", doc.payload.data())
        return doc.payload.data()
      }))
    // })
  }
  getCompanyDetail() {
    // return new Promise((res, rej) => {
    return this.db.doc(`${this.gUrlProvider.getItem('companyUrl')}`)
      .snapshotChanges().pipe(map((doc) => {
        // console.log("data", doc.payload.data())
        return doc.payload.data()
      }))
    // })
  }


  changeOrderProcessingFromToggel() {
    this.db.doc(`${this.gUrlProvider.getItem('systemUrl')}`).update({ isOrderProcessing: false })
  }


  // getStockData = async () => {

  //   await getSystemPath()
  //     .collection('stocks').doc('product_data')
  //     .onSnapshot(async (doc) => {
  //       let arr = []
  //       let categories = []
  //       if (doc.data()) {
  //         await Promise.all(await doc.data().data.map(async obj => {
  //           let data = await getProductById(obj.id);
  //           // console.log("data",data)
  //           if (data) {
  //             if (!(data.categories.includes('all') || data.categories.includes('All')))
  //               data.categories.push('all')
  //             categories.push(...data.categories)
  //             arr.push({
  //               ...obj,
  //               ...data
  //             })
  //           }
  //         }));
  //         //FILTERING SAME CATEGORIES

  //         categories = [...new Set(categories)];

  //         // fs.unlink(setupPath+"channelMapping.json", (err) => {
  //         //     if (err) throw err;
  //         let inventory = await getAllChannelInventory();
  //         let filteredInventory = _.filter(inventory, (o) => {
  //           return o.productName || o.maxLimit;
  //         });

  //         // console.log("getAllChannelInventory", filteredInventory)

  //         filteredInventory.forEach((obj) => {
  //           var index = _.findIndex(arr, {
  //             id: obj.productId
  //           });
  //           console.log("index", index)
  //           if (index >= 0) {
  //             if (obj.productName)
  //               if (obj.productName.length > 0)
  //                 arr[index].name = obj.productName;
  //             if (obj.maxLimit)
  //               arr[index].maxLimit = Number(obj.maxLimit);
  //           }

  //         })


  //         // setTimeout(() => {

  //         // socketController.vmChannels();
  //         return true;
  //         // }, 5000)
  //         // });
  //       }
  //     })
  // }

  // getProductById(id) {
  //   return new Promise((res, rej) => {
  //     this.getModel(id).subscribe((doc => {
  //       res(doc)
  //     }), error => rej(error));
  //   })
  // }


  getList1() {

    return this.getList(`${this.gUrlProvider.getItem('productUrl')}`)


    // })
  }

  public set isKantineChanged(v: Boolean) {
    this._isKantineChanged = v;
  }


  public get isKantineChanged(): Boolean {
    return this._isKantineChanged
  }



}
