import { ProductsModel } from "../products/products.model";

export class CartModel extends ProductsModel {
    public isCheck: boolean;
    public num: number;
    public status : string;
    public constructor(model: any = {}) {
        super(model);
        this.isCheck = model.isCheck;
        this.num = model.num || 0;
        this.status = "recevied"
    }
}
