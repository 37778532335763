import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import { CustomSkeletonImgComponent } from './custom-skeleton-img/custom-skeleton-img.component';
import { CustomOkComponent } from './custom-ok/custom-ok.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule.forRoot(),
        RouterModule,

    ],
    declarations: [
      CustomSkeletonImgComponent,
      CustomOkComponent
    ],
    exports: [
       CustomSkeletonImgComponent,
      CustomOkComponent
    ],
    entryComponents: [],
})
export class ComponentsModule {
}
