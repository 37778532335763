import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BaseService } from './base.service';
import { MyError } from './my-error';
import {  Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GlobalProvider, GlobalUrlProvider } from '../global.provider';
import { UserModel } from '../login/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<UserModel> {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private gUrlProvider: GlobalUrlProvider,
    public db: AngularFirestore,
    public myErr: MyError) {
    super(db, 'productUrl', myErr);
  }



  userLoginDetail(email, password) {
    // return new Promise((res, rej) => {
    return this.db.collection(`${this.gUrlProvider.getItem('userUrl')}`, ref => ref.where("email", '==', email).where('password', "==", password))
      .snapshotChanges().pipe(map((doc) => {
        // console.log("data", doc.payload.data());
        return doc
      }))
    // })
  }

  setUser(user): any {

    this.gUrlProvider.setItem('user', JSON.stringify(user));

  }

  resetPassword(email): any {

    return new Promise((res, rej) => {
      let obj = JSON.stringify({ companyId: this.gUrlProvider.getItem('companyId'), siteId: this.gUrlProvider.getItem('siteId'), email: email })
      this.http.post(environment.backEnd + "/user1/forgotPassword", obj, this.httpOptions).subscribe((data) => {
        // console.log(data)
        res(data['success']);
      }, error => rej(error))
    })

  }

  getUser() {

    return JSON.parse(this.gUrlProvider.getItem('user'));
  }

  getUserCredit(): Observable<any> {
    let userId = JSON.parse(this.gUrlProvider.getItem('user')).uid;
    // return this.db.collection(`${this.gUrlProvider.getItem('userUrl')}`).doc(userId)
    return this.db.doc<any>(`${this.gUrlProvider.getItem('userUrl')}/${userId}`).snapshotChanges()
      .pipe(
        map(d => {
          return ({ id: d.payload['id'], ...d.payload.data() });
        }),
        catchError(this.myErr.handleError)
      );

  }

  getUserLanguage(): Observable<any> {
    let userId = JSON.parse(this.gUrlProvider.getItem('user')).uid;
    // return this.db.collection(`${this.gUrlProvider.getItem('userUrl')}`).doc(userId)
    return this.db.doc<any>(`${this.gUrlProvider.getItem('userUrl')}/${userId}`).snapshotChanges()
      .pipe(
        map(d => {
          return ({ id: d.payload['id'], ...d.payload.data() });
        }),
        catchError(this.myErr.handleError)
      );

  }


  getGroupDetail(groupId) {
    return new Promise((res, rej) => {
      this.db.collection(`${this.gUrlProvider.getItem('siteUrl')}/empGroups`, ref => ref.where('groupId', '==', groupId)).get().subscribe(sp => {
        sp.forEach(doc => {
          // console.log(doc.data())
          res(doc.data())
        })
      })
    })
  }

  updateCredit(userId, credit) {
    return new Promise((res, rej) => {

      this.db.collection(`${this.gUrlProvider.getItem('userUrl')}`).doc(userId).update({ credit: credit }).then((data) => {
        res(data);
      }).catch(err => {
        rej(err)
      })
    })
  }

  updateLanugage(userId, lang) {
    return new Promise((res, rej) => {

      this.db.collection(`${this.gUrlProvider.getItem('userUrl')}`).doc(userId).update({ language: lang }).then((data) => {
        res(data);
      }).catch(err => {
        rej(err)
      })
    })
  }


  changeOrderProcessingFromToggel() {
    this.db.doc(`${this.gUrlProvider.getItem('systemUrl')}`).update({ isOrderProcessing: false })
  }




}
