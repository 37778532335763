

export class ProductsModel {
  public id?: string;
  public name: string;

  public price: number;
  public bucketImages : Array<object> ;
  public categories : Array<string>;
  public description: string;
  public maxLimit: string
  public productNumber: string
  public productNumberLowerCase: string
  public searchString: string
  public threshold: number
  public ref:string
  public mva:string
  public imageUrl:  string

  constructor(good: any = {}) {
    this.id = good.id;
    this.imageUrl = good.imageUrl;
    this.name = good.name || '';
    this.description = good.description || ''
    this.price = good.price || 0;
    this.mva = good.mva || 0;
    this.ref = good.ref;
  }
}



